import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I'm Will. I'm an engineer with a PhD in Unsupervised Change Detection. I'm the co-founder of `}<a parentName="p" {...{
        "href": "https://sourcepulp.consulting"
      }}>{`Sourcepulp`}</a>{`, a growing,
award-winning agile software consultancy. Since 2013 we've been helping clients ranging from micro businesses to government departments deliver new projects,
rescue legacy code nightmares and digitally transform.`}</p>
    <p>{`If you'd like to get in touch, drop me an email at `}<a parentName="p" {...{
        "href": "mailto:will@faithfull.me"
      }}>{`will@faithfull.me`}</a>{`, or DM me on `}<a parentName="p" {...{
        "href": "https://twitter.com/DrFaithfull"
      }}>{`Twitter`}</a>{`. `}<a parentName="p" {...{
        "href": "cv.pdf"
      }}>{`Here`}</a>{`'s my CV.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      